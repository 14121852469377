import React from 'react'
import Img from 'gatsby-image'

import {graphql, StaticQuery, Link} from 'gatsby'

import Layout from '../containers/layout'
import SEO from '../components/seo'
// import Container from '../components/container'

// import BlockContent from '../components/portableText'

import styles from '../components/page.module.css'

export default class Index extends React.Component {
  render () {
    return (

      <StaticQuery
        query={graphql`
            query sanityHomePage {
              sanityHome {
                title
                mainImage {
                  asset {
                    fluid {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
                homeCTA {
                  _key
                  title
                  link
                  image {
                    asset {
                      fixed(height: 270) {
                      ...GatsbySanityImageFixed
                    }
                    }
                  }
                  icon {
                    asset {
                      url
                    }
                  }
                }
                homeStat {
                  _key
                  title
                  statNumber
                }
                seoSettings {
                  title
                  description
                  openGraphImage {
                    asset {
                      fluid {
                      ...GatsbySanityImageFluid
                      }
                    }
                  }
                }
              }
            }
          `}
        render={data => (
          <>
            {data.sanityHome.seoSettings && data.sanityHome.seoSettings.title && data.sanityHome.seoSettings.description && (<SEO title={data.sanityHome.seoSettings.title} description={data.sanityHome.seoSettings.description} />)}
            <div className={styles.wrapper}>
              <div className={styles.homeTitle}>
                <h2>CHaracter in Athletics</h2>
                <h3>Make it A Priority</h3>
              </div>
            </div>

            <Layout Layout mainImage={data.sanityHome.mainImage} homePage>

              <div className={styles.homeContentWrapper}>

                <div className={styles.mainContent}>

                  <div className={styles.homeCTAWrapper}>
                    {data.sanityHome.homeCTA.map(cta => (

                      <div key={cta._key} className={styles.homeCTA}>
                        <Link to={cta.link}>
                          <div className={styles.homeCTAImage}>
                            <Img fixed={cta.image.asset.fixed} alt={cta.title} />
                          </div>

                          <div className={styles.homeCTAText}>
                            <div>
                              <img src={cta.icon.asset.url} alt={cta.title} />
                            </div>
                            <div>
                              <h3>{cta.title}</h3>
                            </div>

                          </div>

                        </Link>
                      </div>

                    ))}
                  </div>

                  <div className={styles.homeBottomContent}>
                    {data.sanityHome.homeStat.map(stat => (
                      <div key={stat._key} className={styles.homeStat}>
                        <h3>{stat.title}</h3>
                        <p>{stat.statNumber}</p>
                      </div>
                    ))}
                  </div>

                </div>

              </div>

              {/* <article className={styles.root} >

                <Container>
                  container
                </Container>

              </article> */}

            </Layout>
          </>
        )}
      />
    )
  }
}
